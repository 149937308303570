
import PathContext from "./context/path";
import Wrap from './pages/Wrap/Wrap';
function App() {

  const basenameContext = '.';
  return (
    <PathContext.Provider value={basenameContext}>
      <Wrap />
    </PathContext.Provider>
  );
}

export default App;
