import React, { useContext, useState } from "react";
import styles from "./Main.module.scss";
import StateContext from "../../context/state";
import PathContext from "../../context/path";
import Calendar from '../../components/Calendar/Calendar';
import Switcher from '../../components/Switcher';
import TypeSwitcher from '../../components/TypeSwitcher';
import List from '../../components/List';
import { useRef } from 'react';
import { useEffect } from 'react';
const Main = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const refHeader = useRef(null);
  const refHeaderDesc = useRef(null);
  const refHeaderBg = useRef(null);
  const refBody = useRef(null);
  const { listType } = state;
  const [currentListType, setCurrentListType] = useState(0);
  useEffect(() => {

    const header = refHeader.current;
    const options = {
      rootMargin: "0% 0% -100% 0%",
      threshold: 0,
    };
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.fixed);
        } else {
          entry.target.classList.remove(styles.fixed);
        }
      });
    }
    const observer = new IntersectionObserver(callback, options);
    observer.observe(header);

    document.querySelector('body').style.opacity = 1;

    return () => {
      observer.unobserve(header);
    }
  }, []);

  // useEffect(()=>{
  //   if(listType === currentListType) return;
  //   const body = refBody.current;
  //   let timer;
  //   body.style.opacity = '0';
  //   setTimeout(() => {
  //     setCurrentListType(listType);
  //     body.style.opacity = '1';
  //   }, 300);
  //   return()=>{
  //     clearTimeout(timer);
  //   }
  // },[listType, currentListType]);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.cover}>
          <div className={styles.cover__info}>
            <div className={styles.cover__title}>
              <span>Цели,</span> достойные тебя</div>
          </div>
          <div className={styles.cover__img}>
            <img src={`${path}/img/cover.webp`} alt="" />
          </div>
        </div>
        <div className={styles.header} ref={refHeader}>
          <div className="container">
            <div className={styles.header__inner}>
              <div className={styles.header__logo}>
                <img src={`${path}/img/logo.svg`} alt="" />
              </div>
              <div className={styles.header__desc} ref={refHeaderDesc}>Цели, достойные тебя</div>
            </div>
          </div>
          <div className={styles.header__bg} ref={refHeaderBg}></div>
        </div>
        <div className={styles.panel}>
          <div className="container">
            <div className={styles.panel__inner}>
              <h1 className={styles.title}>Календарь HR-активностей</h1>
              <div className={styles.switcher}>
                <Switcher />
              </div>
            </div>

            <div className={styles.type}>
              <TypeSwitcher />
            </div>
          </div>
        </div>
        <div className={styles.body} ref={refBody}>
          {listType === 0 ?
            <Calendar />
            :
            <List />
          }
        </div>
      </div>
    </>
  );

}

export default Main;
