import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./DateBar.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
const DateBar = (props) => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const refWrap = useRef(null);
  const { weekdate, realdate, widthMonth, link } = props;
  const { dateView } = state;
  const [status, setStatus] = useState(0);
  const [currentDate, setCurrentDate] = useState([]);
  const [date, setDate] = useState([null, null]);


  useEffect(() => {
    let week0, week1;
    const monthNames = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"];
    const monthsLength = [4, 4, 5, 4, 5, 4, 4, 5, 4, 4, 5, 4];

    const getWeekNumber = (date) => {
      const currentDay = new Date(date);
      const onejan = new Date(currentDay.getFullYear(), 0, 1);
      const millisecsInDay = 86400000;
      const weekNumber = Math.ceil((((currentDay - onejan) / millisecsInDay) + onejan.getDay() + 1) / 7);
      return weekNumber;
    }

    const getDateFromWeekNumber = (year, unformatDate) => {
      let date;
      if (realdate !== undefined) {
        date = new Date(unformatDate);
      } else {
        date = new Date(year, 0, 1 + (unformatDate - 1) * 7);
      }
      const day = date.getDay();
      date.setDate(date.getDate() + (day <= 4 ? 1 - day : 8 - day));
      return date;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let startDate, endDate;

    if (realdate !== undefined) {
      week0 = getWeekNumber(realdate[0]);
      week1 = getWeekNumber(realdate[1]);
      startDate = new Date(realdate[0]);
      endDate = new Date(realdate[1]);
    } else {
      week0 = weekdate[0];
      week1 = weekdate[1];
      startDate = getDateFromWeekNumber(2024, week0);
      endDate = getDateFromWeekNumber(2024, week1);
      endDate.setDate(endDate.getDate() + 6);
    }

    const difDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
    const difWeeks = week1 - week0 + 1;
    setDate([startDate, endDate]);


    const getTodayIndex = () => {
      const start = new Date(today.getFullYear(), 0, 0);
      const diff = startDate - start;
      const oneDay = 1000 * 60 * 60 * 24;
      return Math.floor(diff / oneDay) - 1;
    }
    const getMonthsAndWeeks = (dateRange) => {
      let weekCount = 0;
      const result = [];

      for (let i = 0; i < monthsLength.length; i++) {
        const monthStartWeek = weekCount + 1;
        const monthEndWeek = weekCount + monthsLength[i];

        if (monthEndWeek >= dateRange[0] && monthStartWeek <= dateRange[1]) {
          const startWeekInMonth = Math.max(dateRange[0], monthStartWeek) - weekCount;
          const endWeekInMonth = Math.min(dateRange[1], monthEndWeek) - weekCount;
          const length = endWeekInMonth - startWeekInMonth + 1;
          result.push([i, length]);
        }
        weekCount = monthEndWeek;
      }
      return result;
    }
    const wrap = refWrap.current;
    let offset, width;




    let currentOffset = 0;
    let currentWidth = 0;
    switch (dateView) {
      case 0:
        offset = `${getTodayIndex() * widthMonth + 0.13}rem`;
        width = `${difDays * widthMonth + 0.13}rem`;
        break;
      case 1:
        if (realdate !== undefined) {
          offset = `${((week0 - 1) * (widthMonth + 0.015)) + ((widthMonth + 0.015) * (startDate.getDay()-1) / 7)}rem`;
          width = `${(difDays) * (widthMonth + 0.015) / 7}rem`;
        } else {
          offset = `${(week0 - 1) * (widthMonth + 0.015)}rem`;
          width = `${difWeeks * (widthMonth + 0.015)}rem`;
        }
        break;

      case 2:

        if (realdate !== undefined) {

          let dayInMonth = 33 - new Date(startDate.getFullYear(), startDate.getMonth(), 32).getDate();
          getMonthsAndWeeks([1, week0 - 1]).forEach(element => {
            if (monthsLength[element[0]] === element[1]) {
              currentOffset += widthMonth;
            } else {
              currentOffset += (((element[1]) * (widthMonth + 0.06) / monthsLength[element[0]]) + (startDate.getDay() - 1) * (widthMonth + 0.06) / (monthsLength[element[0]] * 7));
            }
          });
          currentWidth = (difDays - 1) * (widthMonth) / dayInMonth;
        } else {
          getMonthsAndWeeks([1, week0]).forEach(element => {
            if (monthsLength[element[0]] === element[1]) {
              currentOffset += widthMonth;
            } else {
              currentOffset += ((element[1] - 1) * (widthMonth + 0.06) / monthsLength[element[0]]);
            }
          });
          getMonthsAndWeeks([week0, week1]).forEach(element => {
            currentWidth += element[1] * widthMonth / monthsLength[element[0]];
          });
        }
        offset = `${currentOffset}rem`;
        width = `${currentWidth}rem`;
        break;
      case 3:
        if (realdate !== undefined) {

          let dayInMonth = 33 - new Date(startDate.getFullYear(), startDate.getMonth(), 32).getDate();
          getMonthsAndWeeks([1, week0 - 1]).forEach(element => {
            if (monthsLength[element[0]] === element[1]) {
              currentOffset +=  (widthMonth + 0.02);
            } else {
              currentOffset += (((element[1]) * (widthMonth + 0.06) / monthsLength[element[0]]) + (startDate.getDay() - 1) * (widthMonth + 0.06) / (monthsLength[element[0]] * 7));
            }
          });
          currentWidth = (difDays - 1) * (widthMonth) / dayInMonth;
        } else {
          getMonthsAndWeeks([1, week0]).forEach(element => {
            if (monthsLength[element[0]] === element[1]) {
              currentOffset += (widthMonth + 0.02);
            } else {
              currentOffset += ((element[1] - 1) * (widthMonth) / monthsLength[element[0]]);
            }
            // currentOffset += ((element[1]-1) * (widthMonth + 0.06) / monthsLength[element[0]]);
          });
          getMonthsAndWeeks([week0, week1]).forEach(element => {
            currentWidth += element[1] * widthMonth / monthsLength[element[0]];
          });
        }
        offset = `${currentOffset}rem`;
        width = `${currentWidth}rem`;
        break;

      default:
        break;
    }
    wrap.style.left = offset;
    wrap.style.width = width;

    if (startDate <= today && today <= endDate) {
      setStatus(1);
    } else {
      setStatus(0);
    }

    const postFormatDate = (date) => {
      if (dateView > 1) return null;
      let result;
      var day = date.getDate().toString().padStart(2, '0');
      var month = (date.getMonth() + 1).toString().padStart(2, '0');
      var year = date.getFullYear();
      if (difDays < 14 && dateView === 1) {
        result = `${day.replace(/^0+/, '')}`
      } else {
        result = `${day.replace(/^0+/, '')} ${monthNames[month - 1].slice(0, 3).toLowerCase()}`
      }
      return result;
    }
    setCurrentDate([postFormatDate(startDate), postFormatDate(endDate)]);

  }, [weekdate, realdate, widthMonth, dateView]);

  return (
    <>
      <div to={link} className={status === 1 ? `${styles.wrap} ${styles.wrap_1}` : styles.wrap} ref={refWrap}>
        {currentDate.length > 0 ?
          currentDate.map((element, index) => {
            return <span key={`data-${index}`}>
              {element}
            </span>
          })
          : null}
      </div>
    </>
  );

}

export default DateBar;
