import React, { useContext, useEffect, useState } from "react";
import styles from "./Switcher.module.scss";
import StateContext from "../../context/state";
import PathContext from "../../context/path";
import { useRef } from 'react';
const Switcher = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const refBtns = useRef([]);

  const { listType, updateListType } = state;
  const selectRole = () => {
    updateListType(1 - listType);
  }
  useEffect(() => {
    const btns = refBtns.current;
    btns.forEach((element, index) => {
      if(listType === index){
        element.classList.add(styles.active);
      }else{
        element.classList.remove(styles.active);
      }
    });
  }, [listType]);
  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.btn} onClick={() => updateListType(0)} ref={element=>refBtns.current[0]=element}>Хронология</div>
        <div className={styles.btn} onClick={() => updateListType(1)} ref={element=>refBtns.current[1]=element}>Список</div>
      </div>
    </>
  );

}

export default Switcher;
