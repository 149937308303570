import React, { useContext, useEffect, useState } from "react";
import styles from "./Switcher.module.scss";
import StateContext from "../../context/state";
import PathContext from "../../context/path";
import { useRef } from 'react';
const Switcher = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const refWrap = useRef(null);
  const [role, setRole] = useState(0);

  const { roleType, updateRoleType } = state;
  const selectRole = () => {
    updateRoleType(1 - roleType);
  }
  useEffect(() => {
    const wrap = refWrap.current;
    if (roleType === 1) {
      wrap.classList.add(styles.hr);
    } else {
      wrap.classList.remove(styles.hr);
    }
  }, [roleType]);
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.btn} onClick={() => updateRoleType(0)}>Руководитель</div>
        <div className={styles.icon} onClick={() => updateRoleType(prev => 1-prev)}></div>
        <div className={styles.btn} onClick={() => updateRoleType(1)}>HR</div>
      </div>
    </>
  );

}

export default Switcher;
