import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Collapse.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import { Children, isValidElement } from 'react';
const Collapse = (props) => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const refWrap = useRef(null);
  const refBody = useRef(null);
  const refInner = useRef(null);
  const { title, collapseCallback, indexTarget, isList, desc } = props;
  const { roleType } = state;
  const [isOpen, setOpen] = useState(true);
  const [counter, setCounter] = useState(-1);
  useEffect(() => {
    const wrap = refWrap.current;
    setTimeout(() => {

      wrap.classList.remove(styles.init);
    }, 100);
  }, []);
  useEffect(() => {
    const wrap = refWrap.current;
    const body = refBody.current;
    const inner = refInner.current;
    const rem = window.innerWidth * 1.111 / 100;
    let timer;

    if (isOpen) {
      wrap.classList.add(styles.open);
      body.style.height = `${inner.offsetHeight / rem}rem`;
      timer = setTimeout(() => {
        body.style.overflow = `unset`;
      }, 300);
      if (collapseCallback !== undefined) {
        collapseCallback(true, indexTarget, `${inner.offsetHeight / rem}rem`);
      }
    } else {
      wrap.classList.remove(styles.open);
      clearTimeout(timer);
      body.style.height = ``;
      body.style.overflow = ``;
      if (collapseCallback !== undefined) {
        collapseCallback(true, indexTarget, `0rem`);
      }
    }

    return () => {
      clearTimeout(timer);
    }
  }, [collapseCallback, indexTarget, isOpen, props.children, counter]);

  useEffect(() => {
    setTimeout(() => {
      const wrap = refWrap.current;
      if(refInner.current == null) return;
      const counter = refInner.current.children.length;
      if (counter === 0) {
        wrap.style.display = 'none';
      } else {

        wrap.style.display = '';
      }
      setCounter(counter);
    }, 100);

  }, [props.children]);

  useEffect(()=>{
    if(isList !== undefined && isList){
      refWrap.current.classList.add(styles.wrap_list);
    }
  },[isList]);
  const clickHandler = () => {
    setOpen(prev => !prev);
  }

  return (
    <>
      <div className={`${styles.wrap} ${styles.init}`} ref={refWrap}>
        <div className={styles.header} onClick={clickHandler}>
          {title}
          {desc !== undefined ?
             <div className={styles.desc}>
                <div className={styles.desc__icon}>
                  <span>i</span>
                </div>
                <div className={styles.desc__popup}>
                  {desc}
                </div>
              </div>
              : null
            }
          <div className={styles.header__counter}>({counter})</div>
          <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L8 8L15 1" stroke="#012B34" strokeWidth="2" />
          </svg>

        </div>
        <div className={styles.body} ref={refBody}>
          <div className={styles.inner} ref={refInner}>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );

}

export default Collapse;
