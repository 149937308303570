import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./List.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import ListItem from '../ListItem';
import Collapse from '../Collapse';
const List = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const { dataList, roleType, updateViewCards, dateView, updateDateView } = state;
  const [currentDataList, setCurrentDataList] = useState([]);
  const [filteredDataList, setFilteredDataList] = useState([]);
  const [sortId, setSortId] = useState(0);
  const refSort = useRef([]);
  useEffect(() => {
    if (dataList === null) return;
    if (roleType === 0) {
      setCurrentDataList(dataList['leader'])
    } else {
      setCurrentDataList(dataList['hr'])
    }
  }, [dataList, roleType]);

  useEffect(() => {
    const sorts = refSort.current;
    sorts.forEach((element, index) => {
      if (index === sortId) {
        element.classList.add(styles.active);
      } else {
        element.classList.remove(styles.active);
      }
    });
    if (currentDataList.length === 0) return;
    const array = [...currentDataList];
    // let array;
    const today = new Date();

    const getDateFromWeekNumber = (year, weekNumber) => {
      const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
      const day = date.getDay();
      date.setDate(date.getDate() + (day <= 4 ? 1 - day : 8 - day));
      return date;
    }
    // if (sortId === 0) {
    //   array = [...currentDataList];
    // } else {
    //   array = [...currentDataList].map((element) => {
    //     let list = [...element.list].filter((item) => {
    //       let date = [...item.date];
    //       let result = false;
    //       date.forEach(element => {

    //         const startDate = getDateFromWeekNumber(2024, element[0]);
    //         const endDate = getDateFromWeekNumber(2024, element[1]);
    //         if (startDate <= today && today <= endDate) {
    //           result = true;
    //         }
    //       });
    //       return result;
    //     });
    //     element.list = list;
    //     return element;
    //   })
    // }
    // const startDate = getDateFromWeekNumber(2024, weekdate[0]);
    // const endDate = getDateFromWeekNumber(2024, weekdate[1]);
    setFilteredDataList(array);
  }, [sortId, currentDataList]);
  return (
    <>
      <div className={styles.wrap}>
        <div className="container">
          <div className={styles.sort}>
            <span>Отображать:</span>
            <div className={styles.sort__btn} ref={element => refSort.current[0] = element} onClick={() => setSortId(0)}>все</div>
            <div className={styles.sort__btn} ref={element => refSort.current[1] = element} onClick={() => setSortId(1)}>актуальные сегодня</div>
          </div>
          <div className={styles.body}>
            {filteredDataList.length > 0 ?
              filteredDataList.map((element, index) => {
                let items = [];
                element.list.forEach((item, i) => {
                  items.push(<ListItem key={`bar-${index}-${i}`} roleType={roleType} sortId={sortId} name={item.name} desc={item.desc} weekdate={item.date} realdate = {item.realdate} />)
                  // item.date.forEach((e, k) => )
                });
                return <Collapse title={element.title} desc={element.desc} key={`collapse-2-${index}`} isList={true}>
                  {items}
                </Collapse>

              })
              :
              null}
          </div>
        </div>
      </div>
    </>
  );

}

export default List;
