import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Calendar.module.scss";
import PathContext from "../../context/path";
import StateContext from "../../context/state";
import DateBar from '../../components/DateBar';
import Collapse from '../Collapse';
const Calendar = () => {
  const path = useContext(PathContext);
  const state = useContext(StateContext);
  const refTargetBars = useRef([]);
  const refMonths = useRef(null);
  const refToday = useRef(null);
  // const refYears = useRef([]);
  const refTitles = useRef(null);
  const refGrid = useRef(null);
  const refBars = useRef(null);
  const refLed = useRef(null);
  const refInner = useRef(null);
  const refScreen = useRef(null);
  const refScreenScroll = useRef(null);
  const refSort = useRef([]);
  const refNameCols = useRef([]);
  const refBarCols = useRef([]);
  const monthNames = useRef(["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"]);
  const widthMonth = useRef([3.44, 3.44, 6.88, 4.63]);

  const monthsLength = useRef([4, 4, 5, 4, 5, 4, 4, 5, 4, 4, 5, 4]);
  // const [dateView, setDateView] = useState(0);
  const [todayDate, setTodayDate] = useState('');
  // const [monthIndexArray, setMonthIndexArray] = useState([]);
  const monthIndexArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const monthIndexArrayQr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  // const [yearsArray, setYearsArray] = useState([]);
  // const [currentMonthLength, setCurrentMonthLength] = useState(0);

  const { dataList, roleType, updateViewCards, dateView, updateDateView } = state;
  const [currentDataList, setCurrentDataList] = useState(null);
  const [collapseId, setCollapseId] = useState([false, -1]);

  useEffect(() => {
    if (dataList === null) return;
    if (roleType === 0) {
      setCurrentDataList(dataList['leader'])
    } else {
      setCurrentDataList(dataList['hr'])
    }
  }, [dataList, roleType]);


  useEffect(() => {
    if (currentDataList === null || currentDataList.length === 0) return;
    const rem = window.innerWidth * 1.111 / 100;
    const grid = refGrid.current;
    const bars = refBars.current;
    const screenScrollElement = refScreen.current;
    const nameCols = refNameCols.current;
    const barCols = refBarCols.current;
    let tempDateArray = [];

    bars.style.width = `${grid.offsetWidth / rem}rem`;

    if (nameCols.length > 0 && barCols.length > 0) {
      nameCols.forEach((element, index) => {
        if (element === null) return;
        barCols[index].style.height = `${(element.offsetHeight / rem)}rem`
      });
    }

  }, [currentDataList, dateView]);

  // установка текущей даты

  useEffect(() => {

    const todayElement = refToday.current;
    const screenScrollElement = refScreen.current;
    const today = new Date();
    const rem = window.innerWidth * 1.111 / 100;
    let offset;
    const dayInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    setTodayDate(`${today.getDate()} ${monthNames.current[today.getMonth()].toLowerCase()}`);

    const getWeekNumber = (date) => {
      const onejan = new Date(date.getFullYear(), 0, 1);
      const millisecsInDay = 86400000;
      const weekNumber = Math.ceil((((date - onejan) / millisecsInDay) + onejan.getDay() + 1) / 7);
      return weekNumber - 1;
    }

    const getTodayIndex = () => {
      const start = new Date(today.getFullYear(), 0, 0);
      const diff = today - start;
      const oneDay = 1000 * 60 * 60 * 24;
      return Math.floor(diff / oneDay);
    }

    const getMonthsAndWeeks = (dateRange) => {
      let weekCount = 0;
      const result = [];

      for (let i = 0; i < monthsLength.current.length; i++) {
        const monthStartWeek = weekCount + 1;
        const monthEndWeek = weekCount + monthsLength.current[i];

        if (monthEndWeek >= dateRange[0] && monthStartWeek <= dateRange[1]) {
          const startWeekInMonth = Math.max(dateRange[0], monthStartWeek) - weekCount;
          const endWeekInMonth = Math.min(dateRange[1], monthEndWeek) - weekCount;
          const length = endWeekInMonth - startWeekInMonth + 1;
          result.push([i, length]);
        }
        weekCount = monthEndWeek;
      }
      return result;
    }

    let currentOffset = 0;
    switch (dateView) {
      case 0:
        offset = `${getTodayIndex() * widthMonth.current[dateView] - widthMonth.current[dateView] / 2}rem`;
        break;
      case 1:
        offset = `${widthMonth.current[dateView] * getWeekNumber(today) + (widthMonth.current[dateView] * today.getDay() / 7)}rem`;
        break;
      case 2:
        getMonthsAndWeeks([1, getWeekNumber(today)]).forEach(element => {
          if (monthsLength.current[element[0]] === element[1]) {
            currentOffset += widthMonth.current[dateView];
          } else {
            currentOffset += (today.getDate() * (widthMonth.current[dateView] + 0.06) / 30);
          }
        });
        offset = `${currentOffset}rem`;
        break;
      case 3:
        getMonthsAndWeeks([1, getWeekNumber(today)]).forEach(element => {
          if (monthsLength.current[element[0]] === element[1]) {
            currentOffset += widthMonth.current[dateView];
          } else {
            currentOffset += (today.getDate() * (widthMonth.current[dateView] + 0.06) / 30);
          }
        });
        offset = `${currentOffset}rem`;
        break;

      default:
        break;
    }
    const updateTodayElement = () => {
      // todayElement.style.height = `${(screenScrollElement.offsetHeight / rem)}rem`;
      todayElement.style.left = offset;
    }
    updateTodayElement();
    window.addEventListener('resize', updateTodayElement);
    return () => {
      window.removeEventListener('resize', updateTodayElement);
    }
  }, [currentDataList, dateView]);

  useEffect(() => {

    const titles = refTitles.current;
    const months = refMonths.current;
    const today = refToday.current;
    const screenScrollElement = refScreenScroll.current;
    const screenInner = refInner.current;
    const led = refLed.current;
    const sorts = refSort.current;

    sorts.forEach((element, index) => {
      if (index === dateView) {
        element.classList.add(styles.active);
      } else {
        element.classList.remove(styles.active);
      }
    });
    const scrollToView = () => {
      screenScrollElement.scrollLeft = today.offsetLeft - screenScrollElement.offsetWidth / 2;
    }

    const scrollHandler = (e) => {
      const rem = window.innerWidth * 1.111 / 100;

      months.style.transform = `translateX(-${screenScrollElement.scrollLeft / rem}rem) translateZ(0)`;
      today.style.transform = `translateX(-${screenScrollElement.scrollLeft / rem}rem) translateZ(0)`;
      screenInner.style.transform = `translateX(-${screenScrollElement.scrollLeft / rem}rem) translateZ(0)`;
      titles.style.transform = `translateY(-${screenScrollElement.scrollTop / rem}rem) translateZ(0)`;

      if (today.getBoundingClientRect().left - 2 * rem <= screenScrollElement.getBoundingClientRect().left || today.getBoundingClientRect().right + 2 * rem >= screenScrollElement.getBoundingClientRect().right) {
        led.classList.add(styles.hidden);
      } else {
        led.classList.remove(styles.hidden);
      }
    }
    scrollHandler();
    scrollToView();
    screenScrollElement.addEventListener('scroll', scrollHandler);
    led.addEventListener('click', scrollToView);
    return () => {

      screenScrollElement.removeEventListener('scroll', scrollHandler);
      led.removeEventListener('click', scrollToView);
    }
  }, [dateView]);


  let collapseTimer;
  const collapseCallback = (open, index, height) => {
    let bars = refTargetBars.current;
    let body = bars[index].children[0];
    let inner = body.children[0];
    const rem = window.innerWidth * 1.111 / 100;
    body.style.height = height;
  }

  let monthElement = [];
  let gridElement = [];
  let titlesElement = null;
  let barsElement = null;

  if (currentDataList !== null && currentDataList.length > 0) {

    let colTitleCounter = 0;
    titlesElement = currentDataList.map((element, index) => {
      let titles = element.list.map((item, i) => {
        return <div className={styles.col} key={`title-${index}-${i}`} ref={element => refNameCols.current[colTitleCounter++] = element}>
          <div className={styles.title}>
            {item.name}
            {item.desc !== undefined ?
              <div className={styles.desc}>
                <div className={styles.desc__icon}>
                  <span>i</span>
                </div>
                <div className={styles.desc__popup}>
                  {item.desc}
                </div>
              </div>
              : null
            }
          </div>
        </div>
      });
      return <Collapse title={element.title} desc={element.desc} collapseCallback={collapseCallback} indexTarget={index} key={`collapse-${index}`}>{titles}</Collapse>

    });

    let colBarCounter = 0;
    barsElement = currentDataList.map((element, index) => {
      let bars = element.list.map((item, i) => {
        return <div className={styles.col} key={`col-bar-${index}-${i}`} ref={element => refBarCols.current[colBarCounter++] = element}>
          {item.realdate !== undefined ?
            item.realdate.map((e, k) => <DateBar key={`bar-${index}-${i}-${k}`} realdate={[e[0], e[1]]} widthMonth={widthMonth.current[dateView]} />)
            :
            item.date.map((e, k) => <DateBar key={`bar-${index}-${i}-${k}`} weekdate={[e[0], e[1]]} widthMonth={widthMonth.current[dateView]} />)
          }
        </div>
      });
      return <div className={styles.collapse} key={`collapse-bars-${index}`} ref={element => refTargetBars.current[index] = element}><div className={styles.collapse__body} ><div className={styles.collapse__inner} >{bars}</div></div></div>

    });
  }
  switch (dateView) {
    case 0:
      monthIndexArray.forEach((element, index) => {
        const subMonth1 = []
        const subMonth2 = []
        const dayInMonth = new Date(2024, element + 1, 0).getDate()
        for (let i = 0; i < dayInMonth; i++) {
          subMonth1.push(<div className={`${styles.month__sub} ${styles.month__sub_0}`} key={`screen-submonth-${i}`}>{i + 1}</div>);
          subMonth2.push(<div className={`${styles.month__sub} ${styles.month__sub_0}`} key={`screen-submonth-${i}`}></div>);

        }
        monthElement.push(<div className={`${styles.month} ${styles.month_header_0}`} key={`screen-month-${index}`}>
          {subMonth1}
          <div className={styles.month__name}>{monthNames.current[element].slice(0, 3).toLowerCase()}</div>
        </div>);
        gridElement.push(<div className={styles.month} key={`screen-month-${index}`}>{subMonth2}</div>);
      });

      break;

    case 1:
      monthIndexArray.forEach((element, index) => {
        const subMonth = []
        for (let i = 0; i < monthsLength.current[element]; i++) {
          subMonth.push(<div className={`${styles.month__sub} ${styles.month__sub_1}`} key={`screen-submonth-${i}`}></div>);

        }
        monthElement.push(<div className={`${styles.month} ${styles.month_header}`} key={`screen-month-${index}`}>
          {subMonth}
          <div className={styles.month__name}>{monthNames.current[element].slice(0, 3).toLowerCase()}</div>
        </div>);
        gridElement.push(<div className={styles.month} key={`screen-month-${index}`}>{subMonth}</div>);
      });
      break;

    case 2:
      monthIndexArray.forEach((element, index) => {
        // const subMonth = []
        // for (let i = 0; i < monthsLength.current[element]; i++) {
        //   subMonth.push(<div className={`${styles.month__sub} ${styles.month__sub_2} `} key={`screen-submonth-${i}`}></div>);

        // }
        monthElement.push(<div className={`${styles.month}`} key={`screen-month-${index}`}>
          <div className={styles.month__name}>{monthNames.current[element].slice(0, 3).toLowerCase()}</div>
        </div>);
        gridElement.push(<div className={styles.month} key={`screen-month-${index}`}>
        {/* {subMonth} */}
        </div>);
      });
      break;

    case 3:
      monthIndexArrayQr.forEach((element, index) => {
        if (index % 3 === 0) {
          const subMonth1 = [];
          const subMonth2 = [];
          let qrIndex = 1 + (index / 3);
          if (qrIndex > 4) {
            qrIndex = 1 + (index / 3) - 4;
            subMonth1.push(<div className={`${styles.year}`} key={`screen-year-${index}`}><span>2025</span></div>);
          }
          for (let i = 0; i < 3; i++) {
            subMonth1.push(<div className={`${styles.month__sub} ${styles.month__sub_3}`} key={`screen-submonth-${i}`}></div>);
            subMonth2.push(<div className={`${styles.month__sub} ${styles.month__sub_3}`} key={`screen-submonth-${i}`}></div>);
          }
          monthElement.push(<div className={`${styles.month} ${styles.month_header}`} key={`screen-month-${index}`}>
            {subMonth1}
            <div className={styles.month__name}>{qrIndex} кв</div>
          </div>);
          gridElement.push(<div className={styles.month} key={`screen-month-${index}`}>{subMonth2}</div>);
        }
      });
      break;

    default:
      monthElement = null;
      gridElement = null;
      break;
  }
  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.sort}>
          <span>Отображать:</span>
          <div className={styles.sort__btn} ref={element => refSort.current[0] = element} onClick={() => updateDateView(0)}>дни</div>
          <div className={styles.sort__btn} ref={element => refSort.current[1] = element} onClick={() => updateDateView(1)}>недели</div>
          <div className={styles.sort__btn} ref={element => refSort.current[2] = element} onClick={() => updateDateView(2)}>месяцы</div>
          <div className={styles.sort__btn} ref={element => refSort.current[3] = element} onClick={() => updateDateView(3)}>кварталы</div>
        </div>
        <div className={styles.led} ref={refLed}>
          <div className={styles.led__icon}></div>
          <span>сегодня</span>
        </div>
        <div className={styles.table}>
          <div className={styles.today}>
            <div className={styles.today__wrapper}>
              <div className={styles.today__body} ref={refToday}>
                <div className={styles.today__date}> Сегодня ({todayDate})</div>
                <div className={styles.today__line}></div>
              </div>
            </div>
          </div>
          <div className={styles.header}>
            <div className={styles.months} ref={refMonths}>
              {monthElement}


            </div>

          </div>
          <div className={styles.body}>
            <div className={styles.titles}>
              <div className={styles.titles__inner} ref={refTitles}>
                {titlesElement}
              </div>

            </div>
            <div className={styles.scroll}>
              <div className={`${styles.screen} ${styles.screen_scroll}`} ref={refScreenScroll}>
                <div className={styles.inner}>
                  <div className={styles.grid} ref={refGrid}>
                    {gridElement}
                  </div>
                </div>
              </div>
              <div className={styles.scroll__border}></div>
            </div>
            <div className={styles.screen} ref={refScreen}>
              <div className={styles.inner} ref={refInner}>
                <div className={styles.grid} ref={refGrid}>
                  {gridElement}
                </div>
                <div className={styles.bars} ref={refBars}>
                  {barsElement}
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </>
  );

}

export default Calendar;
