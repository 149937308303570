import React, { useContext, useEffect, useState } from "react";
import StateContext from "../../context/state";
import PathContext from "../../context/path";
import Main from '../Main';
const Wrap = () => {
  const path = useContext(PathContext);
  const [roleType, setRoleType] = useState(0); // 0-руководитель, 1-hr
  const [listType, setListType] = useState(0); // 0-хронология, 1-список
  const [dataList, setDataList] = useState(null);
  const [dateView, setDateView] = useState(1); // 0-дни, 1-недели, 2-месяцы, 3-кварталы
  const updateRoleType = (num) => setRoleType(num);
  const updateListType = (num) => setListType(num);
  const updateDateView = (num) => setDateView(num);

  useEffect(() => {
    fetch(
      `${path}/data/data.json`,
      {
        method: 'GET',
        redirect: 'follow'
      })
      .then(response => response.json())
      .then(result => {
        setDataList(result);
      })
      .catch(error => console.log('error', error));

  }, [path]);
  return (
    <>

      <StateContext.Provider value={{roleType: roleType, updateRoleType: updateRoleType, listType: listType, updateListType: updateListType, dataList: dataList, dateView: dateView, updateDateView: updateDateView }}>
        
          <Main />
      </StateContext.Provider>
    </>
  );

}

export default Wrap;
